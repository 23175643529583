import React from 'react';
import './App.css'
import { phoneNumbers } from './constants'
import "antd/dist/antd.css"
import { Table } from 'antd'
const { Column } = Table

const PhoneNumbers  = (props) => {

  return (
    <div className="mb5 center">
      <Table
        dataSource={phoneNumbers}
        rowKey="phoneNumber"
        scroll={{ x: 200 }}
        pagination={false}
      >
        <Column
          title="Name"
          dataIndex="name"
          width={50}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Phone Number"
          dataIndex="phoneNumber"
          width={50}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Email"
          dataIndex="email"
          width={50}
          align="center"
          className="b"
          render={(text, record) => text ? text : "-"}
        />
      </Table>
    </div>
  )
}

export default PhoneNumbers
