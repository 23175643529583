import React from 'react';
// import axios from 'axios'
import './App.css'
import { allPills } from './constants'
import "antd/dist/antd.css"
import { Table } from 'antd'
const { Column } = Table


const FullPillList = (props) => {

  // const [hideHistoryButton, setHideHistoryButton] = useState(false)
  // const fixHistory = () => {
  //   axios.post(backendURL + '/fix-history', {
  //     "morningPillNames": allPills.filter((pill) => pill["hour"].indexOf("9") > -1),
  //     "nightPillNames": allPills.filter((pill) => pill["hour"].indexOf("19") > -1),
  //     "potassiumPill": allPills.filter(
  //       (pill) => pill["name"] === "Klor-Con (Potassium Chloride)"
  //     )})
  //   .then((response) => {
  //     showSuccess("fixed history!")
  //   }).catch((err) => console.log(err))
  //   setHideHistoryButton(true)
  // }


  const getFrequencyText = (text, record) => {
    switch(text){
      case "everyday":
        switch (record.hour.split(",").length) {
          case 1:
            return "Once Daily (9am)"
          case 2:
            return "Twice Daily (9am and 9pm)"
          default:
            return "N/A"
        }
      case "weekly":
        return "Once a week"
      case "MWF":
        switch (record.hour.split(",").length) {
          case 1:
            return "Once (9am) on Mondays, Wednesdays, and Fridays"
          case 2:
            return "Twice (9am and 9pm) on Mondays, Wednesdays, and Fridays"
          default:
            return "N/A"
        }
      default:
        return text
    }
  }

  const importAll = (r) => {
    let images = {}
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item)
      return r(item)
    })
    return images
  }

  const images = importAll(require.context('./images', false, /\.png$/));
  // <Button type="danger ml2 mt4" onClick={fixHistory} disabled={hideHistoryButton}>
  //   Fix History
  // </Button>
  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv4">
        All Medications
      </h1>
      <Table
        dataSource={allPills}
        rowKey="name"
        scroll={{ x: 850 }}
        pagination={false}
      >
        <Column
          title=""
          dataIndex="img"
          width={150}
          align="center"
          render={(text, record) => (<img alt="/images/nothing.png" src={images[text]} height="128" width="128"/>)}
        />
        <Column
          title="Name"
          dataIndex="name"
          width={150}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Dosage"
          dataIndex="dosage"
          width={80}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="How much"
          dataIndex="numPills"
          width={100}
          align="center"
          className="b"
          render={(text, record) => record.numPills ? (`${record.numPills} ${record.numPills === 1 ? "pill" : "pills"}`) : record.amount}
        />
        <Column
          title="Frequency"
          dataIndex="days"
          width={100}
          align="center"
          render={(text, record) => getFrequencyText(text, record)}
        />
        <Column
          title="Comments"
          dataIndex="comments"
          width={80}
          render={(text, record) => text}
        />
      </Table>
    </div>
  )
}

export default FullPillList
