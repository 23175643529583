import React from 'react';
import './App.css'
import { exercises } from './constants'
import "antd/dist/antd.css"
import { Table } from 'antd'
const { Column } = Table

const Exercises = (props) => {

  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv3">
        Exercises
      </h1>
      <Table
        dataSource={exercises}
        rowKey="name"
        scroll={{ x: 500 }}
        pagination={false}
      >
        <Column
          title="Name"
          dataIndex="name"
          width={ 100}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Description"
          dataIndex="description"
          width={200}
          render={(text, record) => text}
        />
        <Column
          title="How Often"
          dataIndex="frequency"
          width={80}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Improves"
          dataIndex="purpose"
          width={100}
          render={(text, record) => text}
        />
      </Table>
    </div>
  )
}

export default Exercises
