import React, { useState } from 'react';
import './App.css'
import "antd/dist/antd.css"
import { Tabs } from 'antd'
import Appointments from './Appointments'
import PhoneNumbers from './PhoneNumbers'

const { TabPane } = Tabs

const Info = (props) =>
{

  const [currentTab, setCurrentTab] = useState("Appointments")

  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv3">
        {currentTab}
      </h1>
      <Tabs defaultActivityKey="Appointments" onChange={(newKey) => setCurrentTab(newKey)}>
        <TabPane tab="Appointments" key="Appointments">
          <Appointments/>
        </TabPane>
        <TabPane tab="Phone Numbers" key="Phone Numbers">
          <PhoneNumbers/>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Info
