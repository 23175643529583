import React, { useState, useEffect } from 'react';
import axios from 'axios'
import moment from 'moment'
import './App.css'
import "antd/dist/antd.css"
import { backendURL } from './constants'
import { Table } from 'antd'
const { Column } = Table

const VitalsHistory = () => {

  const [vitalsHistory, setVitalsHistory] = useState([])
  const getVitalsHistory = () => axios(backendURL + '/vitals-history')

  useEffect(() => {
    // can't have async as an argument to useEffect
    const fetchData = async () => {
      getVitalsHistory().then((response) => setVitalsHistory(response.data))
    }
    fetchData()
  }, [])

  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv4">Past Vitals</h1>
      <Table
        dataSource={vitalsHistory.filter((row) => Object.keys(row).length > 1)}
        rowKey="name"
        scroll={{ x: 850 }}
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="timeTaken"
          width={150}
          align="center"
          render={(text, record) => moment(text).format("lll")}
        />
        <Column
          title="Blood Pressure"
          dataIndex="bloodPressure"
          width={150}
          align="center"
          render={(text, record) => text}
        />
        <Column
          title="Blood Sugar"
          dataIndex="bloodSugar"
          width={80}
          align="center"
          render={(text, record) => (text ? `${text}mg/dL` : "")}
        />
        <Column
          title="Weight"
          dataIndex="weight"
          width={100}
          align="center"
          render={(text, record) => (text ? `${text}kg` : "")}
        />
        <Column
          title="Pulse Rate"
          dataIndex="pulse"
          width={100}
          align="center"
          render={(text, record) => text}
        />
        <Column
          title="Temperature"
          dataIndex="temperature"
          width={100}
          align="center"
          render={(text, record) => (text ? `${text}°F` : "")}
        />
        <Column
          title="Custom Date (Overrides date recorded)"
          dataIndex="date"
          width={100}
          align="center"
          render={(text, record) => text}
        />
        <Column
          title="After Eating?"
          dataIndex="afterEating"
          width={100}
          align="center"
          render={(text, record) => text ? "Yes" : "-"}
        />
        <Column
          title="Before Eating?"
          dataIndex="beforeEating"
          width={100}
          align="center"
          render={(text, record) => text ? "Yes" : "-"}
        />

      </Table>
    </div>
  )
}

export default VitalsHistory;
