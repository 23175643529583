import React from 'react';
import './App.css'
import { appointments } from './constants'
import "antd/dist/antd.css"
import { Table } from 'antd'
const { Column } = Table

const Appointments  = (props) => {
  return (
    <div className="mb5 center">
      <Table
        dataSource={appointments}
        rowKey="date"
        scroll={{ x: 500 }}
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="date"
          className="b"
          width={100}
          render={(text, record) => text}
        />
        <Column
          title="Purpose of Visit"
          dataIndex="purpose"
          width={200}
          align="center"
          render={(text, record) => text}
        />
        <Column
          title="Additional Notes"
          dataIndex="notes"
          width={300}
          align="left"
          render={(text, { notes }) => (
            <ul>{notes && notes.map((note, i) => (<li key={i}>{note}</li>))}</ul>
          )}
        />
      </Table>
    </div>
  )
}

export default Appointments
