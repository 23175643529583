import React, { useEffect, useState } from 'react';
import axios from 'axios'
import './App.css'
import "antd/dist/antd.css"
import { backendURL } from './constants'
import { Table } from 'antd'
const { Column } = Table

const Comments  = (props) => {

  const [comments, setComments] = useState([])
  const getComments = () => axios(backendURL + '/comments')

  useEffect(() => {
    const fetchComments = async () => {
      const returnedComments = await getComments().then((res) => {
        return res.data
      }).catch((err) => {
        console.log("Goddamn it", err)
      })
      setComments(returnedComments)
      props.setCommentAdded(false)
    }
    fetchComments()
  }, [props])

  return (
    <div className="mb5 mt5 center">
      <Table
        dataSource={comments.reverse()}
        rowKey="comment"
        scroll={{ x: 500 }}
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="date"
          className="b"
          width={50}
          render={(text, { date }) => <p className="f5">{date}</p>}
        />
        <Column
          title="Comments"
          dataIndex="comment"
          width={400}
          align="left"
          render={(text, { comment }) => (
            <p className="f4">{comment}</p>
          )}
        />
      </Table>
    </div>
  )
}

export default Comments
