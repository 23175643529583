import React, { useState } from 'react';
import axios from 'axios'
import moment from 'moment'
import './App.css'
import "antd/dist/antd.css"
import PillChart from './PillChart'
import PillHistory from './PillHistory'
import FullPillList from './FullPillList'
import VitalsHistory from './VitalsHistory'
import Comments from "./Comments"
import Info from './Info'
import Exercises from './Exercises'
import { backendURL } from './constants'
import { Switch, Link, Route } from 'react-router-dom'
import { Drawer, Button, Modal, Input, Checkbox, message } from 'antd'

const { TextArea } = Input

const App = () => {

  // const count = useSelector(selectors.selectCount);
  // const dispatch = useDispatch();

  const [showVitalModal, setShowVitalModal] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [comment, setComment] = useState("")
  const [commentAdded, setCommentAdded] = useState(false)
  const [vitals, setVitals] = useState({})
  const [currentTab, setCurrentTab] = useState("pill chart")
  const [drawerClosed, setDrawerClosed] = useState(true)

  const showSuccess = (added_item) => message.success(`Successfully ${added_item}!`)

  const handlePostVitals = (e) => {
    e.preventDefault()
    axios.post(backendURL + '/vitals-history', vitals)
    .then((response) => {
      setShowVitalModal(false)
      setVitals({})
      showSuccess("added vital readings")
    })
  }

  const handlePostComment = (e) => {
    e.preventDefault()
    const newComment = {
      date: moment().format("LLLL"),
      comment
    }
    axios.post(backendURL + '/comments', newComment)
    .then((response) => {
      setShowCommentModal(false)
      setComment("")
      setCommentAdded(true)
      showSuccess("Added a comment!")
    })
  }


/*
<Link to="/medication-history" onClick={() => setCurrentTab("pill history")}>
  <Button type="primary ml2 mt4" disabled={currentTab === "pill history"}>
    Past Medication Intake
  </Button>
</Link>
<Link to="/vitals-history" onClick={() => setCurrentTab("vitals history")}>
  <Button type="primary ml2 mt4" disabled={currentTab === "vitals history"}>
    Past Vitals
  </Button>
</Link>
<Link to="/all-pills" onClick={() => setCurrentTab("all pills")}>
  <Button type="primary ml2 mt4" disabled={currentTab === "all pills"}>
    All Pills
  </Button>
</Link>
*/

  const onChangeVitals = (key, value) => setVitals(Object.assign({...vitals}, { [key]: value }))

  return (
      <section className="mb5 center">
        <div>
          <Drawer
            title="Doctor Section"
            placement="left"
            closable={true}
            onClose={() => setDrawerClosed(true)}
            visible={!drawerClosed}
          >
            <p className={(currentTab === "pill history" ? "fw6 f6 helvetica" : "")}>
              <Link to="/medication-history" onClick={() => setCurrentTab("pill history")}>
                Past Medication Intake
              </Link>
            </p>
            <p className={currentTab === "vitals history" ? "fw6 f6 helvetica" : ""}>
              <Link to="/vitals-history" onClick={() => setCurrentTab("vitals history")}>
                  Past Vitals
              </Link>
            </p>
            <p className={currentTab === "all pills" ? "fw6 f6 helvetica" : ""}>
              <Link to="/all-pills" onClick={() => setCurrentTab("all pills")}>
                  All Pills
              </Link>
            </p>
          </Drawer>
        </div>
        <div className="flex-ns justify-center">
          <Button type="primary mt4" onClick={() => setShowCommentModal(true)}>
            Add a Comment
          </Button>
          <Link to="/comments" onClick={() => setCurrentTab("comments")}>
            <Button type="primary ml4 mt4" disabled={currentTab === "comments"}>
              My Comments
            </Button>
          </Link>
          <Button type="primary ml4 mt4" onClick={() => setShowVitalModal(true)}>
            Add Vital Readings
          </Button>
          <Link to="/" onClick={() => setCurrentTab("pill chart")}>
            <Button type="primary ml4 mt4" disabled={currentTab === "pill chart"}>
              Schedule
            </Button>
          </Link>
          <Link to="/exercises" onClick={() => setCurrentTab("exercises")}>
            <Button type="primary ml4 mt4" disabled={currentTab === "exercises"}>
              Training
            </Button>
          </Link>
          <Link to="/info" onClick={() => setCurrentTab("info")}>
            <Button type="primary ml4 mt4" disabled={currentTab === "info"}>
              Appointments
            </Button>
          </Link>
          <Button type="ghost ml4 mt4" onClick={() => setDrawerClosed(false)}>
            For Doctors
          </Button>
        </div>
        <Modal
          title="Add Comment"
          centered={true}
          visible={showCommentModal}
          okText="Submit"
          onOk={handlePostComment}
          onCancel={() => setShowCommentModal(false)}
        >
          <TextArea
            name="comment"
            rows={4}
            type="text"
            className="w-100"
            placeholder="Example: I went for a walk today for 30 minutes..."
            meta={{ error: 'Required' }}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </Modal>
        <Modal
          title="Enter Vital Sign Information"
          centered={true}
          visible={showVitalModal}
          okText="Submit"
          onOk={handlePostVitals}
          onCancel={() => setShowVitalModal(false)}
        >
          <Input
            addonBefore="Blood Pressure: "
            name="bloodPressure"
            type="text"
            className="w-100"
            placeholder="Ex: 100/70"
            meta={{ error: 'Required' }}
            value={vitals["bloodPressure"]}
            onChange={(event) =>
              onChangeVitals(
                'bloodPressure',
                event.target.value
              )
            }
          />
          <Input
            addonBefore="Blood Sugar: "
            name="bloodSugar"
            type="text"
            className="w-100 mt2"
            placeholder="Ex: 90"
            meta={{ error: 'Required' }}
            value={vitals["bloodSugar"]}
            onChange={(event) =>
              onChangeVitals(
                'bloodSugar',
                event.target.value
            )}
          />
          <Input
            addonBefore="Weight (kg): "
            name="weight"
            type="text"
            className="w-100 mt2"
            placeholder="Ex: 57"
            meta={{ error: 'Required' }}
            value={vitals["weight"]}
            onChange={(event) =>
              onChangeVitals(
                'weight',
                event.target.value
            )}
          />
          <Input
            addonBefore="Pulse Rate: "
            name="pulse"
            type="text"
            className="w-100 mt2"
            placeholder="Ex: 80"
            meta={{ error: 'Required' }}
            value={vitals["pulse"]}
            onChange={(event) =>
              onChangeVitals(
                'pulse',
                event.target.value
            )}
          />
          <Input
            addonBefore="Temp (°F): "
            name="temperature"
            type="text"
            className="w-80 mt2"
            placeholder="Ex: 98.6"
            meta={{ error: 'Required' }}
            value={vitals["temperature"]}
            onChange={(event) =>
              onChangeVitals(
                'temperature',
                event.target.value
            )}
          />
          <Input
            addonBefore="Custom Date?"
            name="date"
            type="text"
            className="w-80 mt2"
            placeholder="01/08/2022 9:30am"
            value={vitals["date"]}
            onChange={(event) =>
              onChangeVitals(
                'date',
                event.target.value
            )}
          />
          <p className="mt3">Did you take these readings before or after eating?</p>
          <div className="flex-ns justify-between">
            <Checkbox
              className="w-20 mt3"
              onChange={(event) =>
                onChangeVitals(
                  'beforeEating',
                  event.target.checked
              )}
            >
              Before
            </Checkbox>
            <Checkbox
              className="w-20 mt3"
              onChange={(event) =>
                onChangeVitals(
                  'afterEating',
                  event.target.checked
              )}
            >
              After
            </Checkbox>
          </div>
        </Modal>
        <Switch>
          <Route path="/" exact>
            <PillChart/>
          </Route>
          <Route path="/medication-history">
            <PillHistory/>
          </Route>
          <Route path="/vitals-history">
            <VitalsHistory/>
          </Route>
          <Route path="/all-pills">
            <FullPillList/>
          </Route>
          <Route path="/info">
            <Info/>
          </Route>
          <Route path="/exercises">
            <Exercises/>
          </Route>
          <Route path="/comments">
            <Comments commentAdded={commentAdded} setCommentAdded={setCommentAdded}/>
          </Route>
        </Switch>
      </section>
  )
}

export default App;
