import React, { useState, useEffect } from 'react';
import axios from 'axios'
import moment from 'moment'
import { backendURL } from './constants'
import './App.css'
import "antd/dist/antd.css"
import { Table } from 'antd'
const { Column } = Table


const PillHistory = () => {

  const [pillHistory, setPillHistory] = useState([])

  const getPillHistory = () => axios(backendURL + '/pill-history')

  useEffect(() => {
    // can't have async as an argument to useEffect
    const fetchData = async () => {
      getPillHistory().then((response) => setPillHistory(response.data))
    }
    fetchData()
  }, [])

  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv4">
        Medication History
      </h1>
      <Table
        dataSource={pillHistory}
        rowKey="name"
        scroll={{ x: 450 }}
        pagination={false}
      >
        <Column
          title="Ingestion Time"
          dataIndex="timeTaken"
          width={80}
          align="center"
          render={(text, record) => moment(text).format('lll')}
        />
        <Column
          title="Name"
          dataIndex="name"
          width={150}
          align="center"
          render={(text, record) => text}
        />
        <Column
          title="How much taken"
          dataIndex="numPills"
          width={50}
          align="center"
          render={(text, record) => record.numPills ? (`${record.numPills} ${record.numPills === 1 ? "pill" : "pills"}`) : record.amount}
        />
        <Column
          title="Dosage"
          dataIndex="dosage"
          width={80}
          align="center"
          render={(text, record) => text}
        />
      </Table>
    </div>
  )
}

export default PillHistory;
